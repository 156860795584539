import React, { useState } from "react";
import classnames from "classnames";
import Seo from "components/seo";
import Layout from "layouts/zh";
import SectionContent from 'components/SectionContent'
import Dialog from 'components/Base/Dialog'

import "styles/base.less";
import * as styles from "./index.module.less";

interface ListItemProps {
  className?: string;
  id?: string | number;
  thumb?: string;
  title?: string;
  desc?: string;
  time?: string;
  price?: string;
  onClick?: (data: ListItemProps) => void;
}
const ListItem: React.FC<ListItemProps> = props => {
  const {
    className,
    id,
    thumb,
    title,
    desc,
    time,
    price,
    onClick = () => {}
  } = props;
  return (
    <div className={classnames(styles.ListItem, className)} onClick={() => onClick({id, title})}>
      <div className={styles.ListItemLeft}>
        <img src={thumb} alt="" />
      </div>
      <div className={styles.ListItemRight}>
        <h2>{title}</h2>
        <p>{desc}</p>
        <div>{time}</div>
      </div>
    </div>
  );
}

interface dataSourceProps extends ListItemProps {
  id: string | number;
} 
interface ListProps {
  className?: string;
  dataSource?: dataSourceProps[],
  onClick: (data: ListItemProps) => void;
}
const List: React.FC<ListProps> = props => {
  const {
    className,
    dataSource,
    onClick
  } = props;
  return (
    <div className={classnames(styles.List, className)}>
      {dataSource?.map(item => <ListItem 
        key={item.id} 
        thumb={item.thumb}
        title={item.title}
        desc={item.desc}
        time={item.time}
        onClick={data => onClick(item)}
      />)}
    </div>
  );
}

const IndustryNews: React.FC = (props) => {

  return (
    <Layout {...props}>
      <IndustryNewsState />
    </Layout>
  );
};

interface IndustryNewsStateProps {
  
}
const IndustryNewsState: React.FC<IndustryNewsStateProps> = (props) => {
  const [current, setCurrent] = useState<ListItemProps>();
  const [visible, setVisible] = useState<boolean>(false);

  const onClickList = (item: ListItemProps) => {
    setCurrent(item);
    setVisible(true);
  }

  return (
    <>
      <Seo
        title="行业资讯"
        description=""
        keywords=""
        saTitle=""
      />
      <main className={classnames(styles.IndustryNews)}>
        <div className={styles.Banner}>
          <h1>行业资讯</h1>
        </div>
        <SectionContent className={styles.ListWrap}>
          <List 
            dataSource={[
              {
                id: 1,
                thumb: require('assets/images/terms/industryNews/thumb-01.jpeg').default,
                title: '神策军 | 我有一本书，你拿思想来换',
                desc: '在分析了 30 多万字的专访稿件与自撰书籍后，不难发现，桑文锋喜欢用两类词，一类是政治，一类是文化。',
                time: '2022-02-16',
                price: '1元/条',
              },
              {
                id: 1,
                thumb: require('assets/images/terms/industryNews/thumb-02.jpeg').default,
                title: '2020，神策数据 + （文末有福利）',
                desc: '今天，是神策数据诞生的 2057 天，我想毫无保留的告诉你 2020 年的故事，年龄 5 岁，遍布 8 地。',
                time: '2022-02-16',
                price: '1元/条',
              },{
                id: 1,
                thumb: require('assets/images/terms/industryNews/thumb-03.jpeg').default,
                title: '一位 CEO 在战略沟通会上的员工问答实录',
                desc: '我希望我们是分布式集群，没有单点，每个同学的脑袋里都装有“战略副本”。我只要做好 ZooKeeper，可以随时调度任何人做 Master。',
                time: '2022-02-16',
                price: '1元/条',
              },{
                id: 1,
                thumb: require('assets/images/terms/industryNews/thumb-04.jpeg').default,
                title: '神策视频号「小数点」正式上线啦！',
                desc: '我是“小数点”！你以为我想“视频带货”？你以为我是官方新闻的小喇叭？错了！高价值、重质量、亲业务，1 分钟，带你进入不一样的认知世界！',
                time: '2022-02-16',
                price: '1元/条',
              },
            ]}
            onClick={onClickList}
          />
        </SectionContent>
      </main>
      <Dialog 
        className={styles.DialogWrap} 
        visible={visible}
        width={580} 
        hideFooter 
        onClose={() => setVisible(false)}
      >
        <div className={styles.DialogContent}>
          <p>本栏目均为付费内容，购买费用为 {current?.price}</p>
        </div>
      </Dialog>
    </>
  );
};

export default IndustryNews;
